//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  data() {
    const defaultColumns = this.$store.state.connections.marketplace.title === "Wildberries" ? [{
      title: "SKU",
      show: true,
      name: "nmid",
      type: "text",
      width: 110,
      filter: "text",
      noOverflow: true
    }, {
      title: "产品识别代码",
      show: true,
      name: "sku",
      type: "text",
      width: 160,
      filter: "text",
      noOverflow: true
    }, {
      title: "品牌",
      show: true,
      name: "brand",
      type: "text",
      filter: "text",
      width: 160
    }, {
      title: "商店名称",
      show: true,
      name: "subject",
      type: "text",
      filter: "text",
      width: 220,
      getImage: item => item.image
    }, {
      title: `尺寸`,
      show: true,
      name: "techSize",
      type: "text",
      filter: "text",
      width: 100
    }, {
      title: `类别`,
      show: true,
      name: "category",
      type: "text",
      filter: "text",
      width: 200
    }, {
      title: `价格`,
      show: true,
      name: "price",
      type: "money",
      width: 120,
      filter: "numberRange"
    }, {
      title: `店铺`,
      show: true,
      name: "user_key_name",
      type: "text",
      width: 120,
      filter: "text"
    }, {
      title: `系数`,
      show: true,
      name: "ratio",
      type: "number",
      width: 140,
      filter: "numberRange"
    }, {
      title: `当前余额(总计)`,
      show: true,
      name: "stock_quantity",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `交货数量(总计)`,
      show: true,
      name: "delivery_quantity",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `库存天数(总计)`,
      show: true,
      name: "days_in_stock",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `销售数量(总计)`,
      show: true,
      name: "total_sales",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `销售速度(总计)`,
      show: true,
      name: "speed_sales",
      type: "number",
      width: 220,
      filter: "numberRange"
    }] : [{
      title: "SKU",
      show: true,
      name: "sku",
      type: "text",
      width: 110,
      filter: "text",
      noOverflow: true
    }, {
      title: "产品识别代码",
      show: true,
      name: "offer_id",
      type: "text",
      width: 160,
      filter: "text",
      noOverflow: true
    }, {
      title: "商店名称",
      show: true,
      name: "name",
      type: "text",
      filter: "text",
      width: 500,
      getImage: item => item.image
    }, {
      title: `类别`,
      show: true,
      name: "category_name",
      type: "text",
      filter: "text",
      width: 200
    }, {
      title: `价格`,
      show: true,
      name: "price",
      type: "money",
      width: 120,
      filter: "numberRange"
    }, {
      title: `店铺`,
      show: true,
      name: "user_key_name",
      type: "text",
      width: 120,
      filter: "text"
    }, {
      title: `系数`,
      show: true,
      name: "ratio",
      type: "number",
      width: 140,
      filter: "numberRange"
    }, {
      title: `当前余额(总计)`,
      show: true,
      name: "stock_quantity",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `交货数量(总计)`,
      show: true,
      name: "delivery_quantity",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `库存天数(总计)`,
      show: true,
      name: "days_in_stock",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `销售数量(总计)`,
      show: true,
      name: "total_sales",
      type: "number",
      width: 220,
      filter: "numberRange"
    }, {
      title: `销售速度(总计)`,
      show: true,
      name: "speed_sales",
      type: "number",
      width: 220,
      filter: "numberRange"
    }];
    return {
      columns: defaultColumns,
      defaultColumns
    };
  },
  methods: {
    async onUpdate(event) {
      const updatedItems = event.update();
      for (const item of updatedItems) {
        if (this.$store.state.connections.marketplace.title === "Wildberries") {
          await this.$store.dispatch("connections/updateWbCalcDeliveryProducts", {
            item
          });
        }
        if (this.$store.state.connections.marketplace.title === "OZON") {
          await this.$store.dispatch("connections/updateOzCalcDeliveryProducts", {
            item
          });
        }
      }
      this.refresh();
    },
    refresh() {
      this.$refs.table.refresh();
    },
    async tableLoader({
      query
    }) {
      const data = await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbCalcDeliveryProducts", "connections/getOzCalcDeliveryProducts", {}, {});
      let stores = [];
      this.reportSettings.stores.forEach(item => {
        var _data$stores;
        data === null || data === void 0 || (_data$stores = data.stores) === null || _data$stores === void 0 || _data$stores.forEach(el => {
          if (item.includes(el.id)) {
            stores.push({
              id: el.id,
              name: el.group_name,
              groupId: el.group_id
            });
          }
        });
      });
      let check = new Set();
      stores = stores.filter(obj => !check.has(obj["name"]) && check.add(obj["name"]));
      if (query.action !== "export") {
        this.columns = this.defaultColumns;
        stores.forEach(item => {
          this.columns = [...this.columns, {
            title: `当前余额 (${item.name})`,
            show: true,
            name: `stock_quantity_g${item.groupId}`,
            type: "number",
            width: 300,
            filter: "numberRange",
            noSettings: true
          }, {
            title: `供应数量 (${item.name})`,
            show: true,
            name: `delivery_quantity_g${item.groupId}`,
            type: "number",
            width: 300,
            filter: "numberRange",
            noSettings: true
          }, {
            title: `销售数量 (${item.name})`,
            show: true,
            name: `total_sales_g${item.groupId}`,
            type: "number",
            width: 300,
            filter: "numberRange",
            noSettings: true
          }, {
            title: `销售速度 (${item.name})`,
            show: true,
            name: `speed_sales_g${item.groupId}`,
            type: "number",
            width: 300,
            filter: "numberRange",
            noSettings: true
          }];
        });
      }
      return data;
    }
  }
};